<template>
	<main class="page-container" id="join-test-main">
		<button class="btn btn-primary btn-login">login-test</button>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'JoinGeneralComplete',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('join', ['userNm']),
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {},
	destroyed() {},
};
</script>
